import React, { useEffect, useState } from 'react';
import { Card, CardContent } from './components/Card';
import { Info } from 'lucide-react';
import { Button } from './components/Button';

function Charts() {
  const [markets, setMarkets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [phone,setphone]=useState('');
  const handleClick = (link) => {
    window.open(link, '_blank'); // Opens in a new tab
  };

  useEffect(() => {
    fetch('https://matka555.com/app/api.php')
      .then((response) => response.json())
      .then((data) => {
        setMarkets(data.data);
        setphone(data.phone_number);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching market data:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <section className="mb-12">
      <h2 className="text-3xl font-bold text-center mb-6">
        <span className="text-yellow-400">★</span> AVAILABLE GAMES <span className="text-yellow-400">★</span>
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {markets.map((market, index) => (
          <Card key={index}>
            <CardContent className="p-4">
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-lg font-semibold text-teal-600">{market.market}</h3>
                <Info className="text-teal-600" />
              </div>
              <div className='flex justify-between'>
                <div>
                <p className="text-2xl font-bold mb-2">{market.result}</p>
              <p className={`mb-2 ${market.is_open === '1' ? 'text-green-500' : 'text-red-500'}`}>
                {market.is_open === '1' ? 'Open for betting' : 'Close for today'}
              </p>
              <div className="flex space-x-2 mb-2">
              <Button onClick={() => handleClick('https://matka555.com/app/')}  variant="outline" size="sm">Pana Chart</Button>
                <Button onClick={() => handleClick('https://matka555.com/app/')}  variant="outline" size="sm">Jodi Chart</Button>
           
              </div>
                </div>
                <img onClick={() => handleClick('https://matka555.com/app/')} className=' ml-auto w-20 h-20' src={require('./IMG_9562.PNG')} />
                <div>

                </div>
              </div>
            
              <div className="flex justify-between text-sm text-gray-600">
                <span>Open Bids: {market.open_time}</span>
                <span>Close Bids: {market.close_time}</span>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </section>
  );
}

export default Charts;
