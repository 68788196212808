import React, { useEffect, useState } from "react";
import { Button } from "./components/Button"; // Update to correct path
import { Card, CardContent } from "./components/Card"; // Update to correct path
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./components/Accordion"; // Update to correct path
import { Download, Info } from "lucide-react"; // Icon package remains the same
import { Link } from "react-router-dom"; // Updated from Next.js Link to React Router Link
import './index.css';
import myImage from './logo192.png';

//import { Download } from "lucide-react"; // Assuming you're using this icon package

export function CustomDownloadButton({ children, className = "", ...props }) {

  
  return (
    <button
      className={`flex items-center justify-center w-full py-4 px-6 bg-gradient-to-r from-teal-500 to-blue-500 text-white rounded-lg shadow-lg hover:from-teal-600 hover:to-blue-600 transition-all duration-300 ease-in-out ${className}`}
      {...props}
    >
      <Download className="mr-3 h-6 w-6" /> {/* Icon on the left */}
      {children}
    </button>
  );
}
export default function App() {
  const [markets, setMarkets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [phone,setphone]=useState('');
  // Fetch market data from the API
  useEffect(() => {
    fetch("https://matka555.com/app/api.php")
      .then((response) => response.json())
      .then((data) => {
        setMarkets(data.data);
        setphone(data.phone_number);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching market data:", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

    const handleClick = (link) => {
      window.open(link, '_blank'); // Opens in a new tab
    };
  
  
  return (
 <>

      <main className="container mx-auto px-4 py-8">
        <section className="text-center mb-12">
          <div className="inline-block bg-teal-100 text-teal-800 px-4 py-2 rounded-full mb-4">Welcome To Kuber 555</div>
          <h1 className="text-4xl font-bold mb-4">Play Big, Win Big</h1>
          <h2 className="text-3xl font-semibold mb-4">Business of Faith</h2>
          <h3 className="text-2xl mb-6">With Confidence</h3>
          <div className="flex justify-center space-x-4 mb-6">
            <Button   onClick={() => handleClick('tel:'+phone)}   variant="outline">Call Now</Button>
            <Button    onClick={() => handleClick(' https://wa.me/'+phone)}  variant="outline">WhatsApp</Button>
          </div>
          <p className="text-xl mb-2">Connect with phone call</p>
          <p className="text-3xl font-bold">{phone}</p>
        </section>

        <section className="mb-12">
        <CustomDownloadButton onClick={() => handleClick('https://matka555.com/app/')} >
  Download App
</CustomDownloadButton>


        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-bold text-center mb-6">
            <span className="text-yellow-400">★</span> Today's Top Winners <span className="text-yellow-400">★</span>
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {["Garima", "Yash", "Ajit", "Amit"].map((name, index) => (
              <Card key={name}>
                <CardContent className="p-4 text-center">
                  <h3 className="text-xl font-semibold text-teal-600">{name}</h3>
                  <p className="text-2xl font-bold">₹{[25000, 45200, 45200, 61000][index]}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-bold text-center mb-6">
            <span className="text-yellow-400">★</span> AVAILABLE GAMES <span className="text-yellow-400">★</span>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {markets.map((market, index) => (
          <Card key={index}>
            <CardContent className="p-4">
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-lg font-semibold text-teal-600">{market.market}</h3>
                <Info className="text-teal-600" />
              </div>
              <div className='flex justify-between'>
                <div>
                <p className="text-2xl font-bold mb-2">{market.result}</p>
              <p className={`mb-2 ${market.is_open === '1' ? 'text-green-500' : 'text-red-500'}`}>
                {market.is_open === '1' ? 'Open for betting' : 'Close for today'}
              </p>
              <div className="flex space-x-2 mb-2">
              <Button onClick={() => handleClick('https://matka555.com/app/')}  variant="outline" size="sm">Pana Chart</Button>
                <Button onClick={() => handleClick('https://matka555.com/app/')}  variant="outline" size="sm">Jodi Chart</Button>
           
              </div>
                </div>
                <img onClick={() => handleClick('https://matka555.com/app/')} className=' ml-auto w-20 h-20' src={require('./IMG_9562.PNG')} />
                <div>

                </div>
              </div>
            
              <div className="flex justify-between text-sm text-gray-600">
                <span>Open Bids: {market.open_time}</span>
                <span>Close Bids: {market.close_time}</span>
              </div>
            </CardContent>
          </Card>
        ))}
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-bold text-center mb-6">Frequently Asked Questions</h2>
          <Accordion type="single" collapsible>
            <AccordionItem value="item-1">
              <AccordionTrigger>What is Kuber 555?</AccordionTrigger>
              <AccordionContent>
                Kuber 555 is an online betting platform offering various games and betting options.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-2">
              <AccordionTrigger>What games are available on Kuber 555?</AccordionTrigger>
              <AccordionContent>
                Kuber 555 offers a variety of games including Matka, Satta, and other betting games.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-3">
              <AccordionTrigger>Does Kuber 555 have a License?</AccordionTrigger>
              <AccordionContent>
                Kuber 555 claims to be operated by a company with a gaming sublicense issued by the Isle of Man e-Gaming.
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </section>
      </main>

      
      </>
  );
}
